:root {
  --background-color: white;
  --font-color: black;
  --menu-hover-color: #E6E6E6;
}

.cbp-spmenu {
  opacity: 0.95;
}

.alert {
  margin-bottom: 15px !important;
}

.progress {
  margin-bottom: 15px !important;
}

input {
  background: white;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

input:focus,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

.tool-title img {
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
}

/* Loader Start */
#loader {
  position: inherit;
  z-index: 1;
  margin: auto;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #0E3747;
  width: 150px;
  height: 150px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin-bottom: 15px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Loader Finish */

typeahead-container {
  display: block !important;
  position: relative !important;
  top: auto !important;
  left: auto !important;
}

button {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.pagination {
  margin: 5px 0 10px 0 !important;
}
